.experience__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  gap: 2rem;
}

.experience__container > div {
  background: var(--color-white);
  padding: 2.4rem 5rem;
  border: 2px solid transparent;
  border-radius: var(--border-radius-1);
  transition: var(--transition);
}

.experience__container > div:hover {
  background: transparent;
  border-color: var(--color-white);
  transform: translateY(-0.5rem);
  cursor: pointer;
}
.experience__container > div:hover .experience__details-icon {
  color: var(--color-primary);
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
}

.experience__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
}
.experience__details {
  display: flex;
  gap: 1rem;
}

.experience__details-icon {
  transition: var(--transition);
}

/* media queries (tablet) */
@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }
  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 rem;
  }

  .experience__content {
    padding: 1rem;
  }
}

/* media queries (mobile) */
@media screen and (max-width: 600px) {
  .experience__container {
    gap: 1rem;
  }
  .experience__container > div {
    width: 100%;
    padding: 2rem 1rem;
  }
}
