.portfolio__container {
  display: grid;
  /* width: min-content; */
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__item {
  background: var(--color-light);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 2px solid transparent;
  transition: var(--transition);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio__item:hover {
  border-color: var(--color-light);
  transform: translateY(-0.5rem);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  bottom: 1rem;
}

/* media query (medium devices) */
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.2rem;
  }
}

/* media query (small devices) */
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
