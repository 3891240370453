footer {
  position: relative;
  width: 100%;
  background: #690cc6;
  border-top: solid transparent;
  min-height: 100px;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
}

footer .social_icons,
footer .menu {
  position: relative;
  display: flex;
  justify-self: center;
  align-items: center;
  margin: 10px 0;
}

footer .social_icons li,
footer .menu li {
  list-style: none;
}

footer .social_icons li a {
  font-size: 2em;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
}

footer .social_icons li a:hover {
  transform: translate(-10px);
}

footer .menu li a {
  font-size: 1.2em;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  text-decoration: none;
  opacity: 0.75;
}
footer .menu li a:hover {
  opacity: 1;
}
footer small {
  color: var(--color-light);
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 0.8rem;
}

footer .wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(../../assets/wave.png);
  background-size: 1000px 100px;
}

footer .wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWave 4s linear infinite;
}
footer .wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animateWave_02 4s linear infinite;
}
footer .wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWave 3s linear infinite;
}
footer .wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animateWave_02 3s linear infinite;
}

@keyframes animateWave {
  0% {
    background-position-x: 1000px;
  }

  100% {
    background-position-x: 0px;
  }
}

@keyframes animateWave_02 {
  0% {
    background-position-x: 0px;
  }

  100% {
    background-position-x: 1000px;
  }
}

/* media query (for small devices) */

@media screen and (max-width: 600px) {
  footer .social_icons li a {
    font-size: 1.5rem;
  }
  footer .menu li a {
    font-size: 1rem;
    margin: 0 0.5rem;
  }
  footer small {
    font-size: 0.5em;
  }
}
