.container.testimonials__container {
  width: 40%;
  padding: 0 3rem 4rem;
  /* insted of showing all in one use swipper from swipper.js */
  /* display: flex;
  flex-direction: column;
  gap: 1rem; */
}
.client__avatar {
  width: 4rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 1rem;
  border: 0.4rem solid var(--color-white);
}

.testimonial {
  background: var(--color-white);
  border: 2px solid var(--color-white);
  text-align: center;
  border-radius: 2rem;
  user-select: none;
}

.client__review {
  color: #252525;
  font-weight: 300;
  display: block;
  width: 80%;
  margin: 0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--color-primary);
}

/* media queries (medium devices) */
@media screen and (max-width: 1024px) {
  .container.testimonials__container {
    width: 60%;
  }
}

/* media queries (small devices) */
@media screen and (max-width: 600px) {
  .container.testimonials__container {
    width: var(--container-width-sm);
  }
  .container_review {
    width: var(--container-width-sm);
  }
}
